import React, { useState } from "react";
import {
    AppBar,
    Box,
    Container,
    Grid,
    IconButton,
    Link,
    Menu,
    MenuItem,
    Toolbar,
    Typography
} from "@mui/material";
import { navBarPagesValues, PathRouteInterface } from "./NavBarPages";
import MenuIcon from '@mui/icons-material/Menu';
import SideMenuDrawer from "../drawer/drawer";
import { WINAHOST_HORIZONTAL_LOGO } from "../../Shared/Constants/imageConstants";
import ScreenSizeResource from "../../Shared/utils/screenSizeResource";
import {ES_PRICES, EN_PRICES, MAIN_ROUTE, ENGLISH_MAIN_ROUTE} from "../../routes/constantsRoutes";
import { useLanguageContext } from "../../context/languageProvider";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function NavBar() {
    const lang = useLanguageContext();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { width } = ScreenSizeResource();
    const [openDrawer, setOpenDrawer] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const openLanguageDialog = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLanguageChange = (language: string) => {
        navigate(language === 'es' ? MAIN_ROUTE : `/${language}`);
        window.location.reload();
    };

    const mainRoute: string = lang.language === 'es' ? '/' : `/${lang.language}/`;

    const styles = {
        logoImageSize: {
            width: width >= 500 ? '160px' : width >= 320 ? '120px' : '100px'
        },
        estimateContent: {
            maxWidth: width < 320 ? '110px' : '130px',
            width: '100%',
            margin: width < 400 ? '5px auto' : '8px auto',
            padding: width < 320 ? '4px 6px' : '5px 8px',
            fontSize: width < 320 ? '9px' : '10px',
            color: 'white',
            backgroundColor: '#008080',
            borderRadius: 5,
            boxShadow: '0 2px 5px 0 rgba(0,0,0,0.3)',
            textAlign: 'center',
            display: 'inline-block'
        },
        languageSelector: {
            cursor: 'pointer',
            fontSize: '14px',
            fontWeight: 'bold',
            marginLeft: '15px',
            padding: '5px 4px',
            borderRadius: '5px',
            backgroundColor: 'grey',
            color: 'white',
            height: '32px',
            marginTop: '9px',
            '&:hover': {
                backgroundColor: '#006666'
            }
        }
    };
    

    return (
        <AppBar position="fixed" style={{ backgroundColor: 'white', boxShadow: 'none' }}>
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Box sx={{ display: { xs: 'none', md: 'flex' }, mx: 2 }}>
                        <Link rel={'canonical'} key={'main_page'} href={mainRoute}>
                            <img src={WINAHOST_HORIZONTAL_LOGO} alt={"logo winahost"} style={{ width: '200px' }} />
                        </Link>
                    </Box>

                    <Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'none' } }} style={{ width: '100%' }}>
                        <Grid container alignItems="center">
                            <Grid item xs={4} textAlign={'left'}>
                                <Link rel={'canonical'} key={'main_page'} href={mainRoute}>
                                    <img src={WINAHOST_HORIZONTAL_LOGO} alt={"logo winahost"} style={styles.logoImageSize} />
                                </Link>
                            </Grid>

                            <Grid item xs={6} textAlign={'right'}>
                                <Link rel={'canonical'} key={'1'} underline="none"
                                    href={lang.language === 'es' ? ES_PRICES : ENGLISH_MAIN_ROUTE + EN_PRICES + '#calculatorDegree'} style={styles.estimateContent}>
                                    {t('web.navBar.estimate')}
                                </Link>
                            </Grid>

                            <Grid item xs={2} textAlign={'center'}>
                                <IconButton aria-label="menu" size="small"
                                    onClick={() => setOpenDrawer(true)} color={"default"}>
                                    <MenuIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'flex-end' }}>
                        {navBarPagesValues.map((page: PathRouteInterface) => (
                            <Link rel={'canonical'} key={page.id} underline="none"
                                href={page[`path_${lang.language}`]} style={{
                                    margin: '12px 10px',
                                    padding: '4px 8px',
                                    fontSize: '14px',
                                    color: 'black',
                                    backgroundColor: 'white',
                                    borderRadius: 30,
                                    boxShadow: '0 2px 4px 0 rgba(0,0,0,0.2)'
                                }} target={page.target}>
                                {t(page.name)}
                            </Link>
                        ))}

                        <Box sx={styles.languageSelector} onClick={handleClick}>
                            ESP | ENG
                        </Box>

                        <Menu id={'menu-language-menu'} anchorEl={anchorEl} open={openLanguageDialog} onClose={handleClose}>
                            <MenuItem onClick={() => handleLanguageChange('es')}>
                                🇪🇸 <Typography sx={{ ml: 1 }}>ESP</Typography>
                            </MenuItem>
                            <MenuItem onClick={() => handleLanguageChange('en')}>
                                🇺🇸 <Typography sx={{ ml: 1 }}>ENG</Typography>
                            </MenuItem>
                        </Menu>
                    </Box>

                    <SideMenuDrawer openDrawer={openDrawer} closeDrawer={() => setOpenDrawer(false)}
                        pages={navBarPagesValues} lang={lang.language} />
                </Toolbar>
            </Container>
        </AppBar>
    );
}

export default NavBar;
